import React from 'react';
import { navigate } from 'gatsby';
//Components
import Footer from '../mobile/footer';
import Icon from '../global/icon';
import Photo from './photo';
//Img
import location from '../../images/location.svg';
import whatsapp from '../../images/whatsapp.svg';
import phone from '../../images/phone.svg';
import mail from '../../images/mail.svg';
//Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//Styles
import cardStyles from './card.module.css';

const Data = ({ DataIcon, href, label }) => (
  <a
    className={cardStyles.dataAnchor}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    <Grid
      className={cardStyles.data}
      container
      alignItems="center"
      justify="flex-start"
      spacing={1}
    >
      <Grid item xs={2}>
        <Icon Icon={DataIcon} />
      </Grid>
      <Grid item xs={10}>
        <Typography align="left" noWrap>
          {label}
        </Typography>
      </Grid>
    </Grid>
  </a>
);

export default ({ background, contact, logo, seller }) => (
  <div
    className={cardStyles.root}
    style={{ background: `url(${background})`, backgroundSize: 'cover' }}
  >
    <div>
      <img className={cardStyles.logo} src={logo} alt="" />
    </div>
    <div className={cardStyles.data}>
      <Photo img={seller.img} />
      <div className={cardStyles.name}>
        <span>{seller.name}</span>
      </div>
      <div className={cardStyles.job}>
        <span>{seller.job}</span>
      </div>
      <div>
        <Data
          href={`tel:${seller.phoneNumber}`}
          label={seller.phoneNumber}
          DataIcon={phone}
        />
        <Data
          href={`https://wa.me/${seller.phoneNumber}`}
          label={seller.phoneNumber}
          DataIcon={whatsapp}
        />
        <Data
          href={seller.location.href}
          label={seller.location.label}
          DataIcon={location}
        />
        <Data
          href={`mailto:${seller.email}`}
          label={seller.email}
          DataIcon={mail}
        />
      </div>
    </div>{' '}
    <Footer
      button="PROMOCIONES"
      handleClick={() => navigate(`${window.location.pathname}/promociones`)}
      contact={contact}
    />
  </div>
);
