import React from 'react';
//Material UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    border: '3px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '130px',
    display: 'flex',
    height: '130px',
    justifyContent: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    padding: '5px',
    width: '130px',
  },
  img: {
    height: 'auto',
    width: '150px',
  },
}));

export default ({ img }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img className={classes.img} src={img} alt="" />
    </div>
  );
};
